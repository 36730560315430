export default [
  {
    active: "info",
    actionKey: "mb",
    name: "Mark Bundschuh",
    image: "img/board/mark.jpg",
    role: "President",
    email: "bundschuh.15@osu.edu",
    bio: `Your local computer guy.`,
    quote: `"Talk is cheap. Show me the code." - Linus Torvalds`,
    linkedin: "https://linkedin.com/in/mark-bundschuh-18a27a26a",
    github: "https://github.com/mbund",
  },
  {
    active: "info",
    actionKey: "js",
    name: "Joshua Sims",
    image: "img/board/josh-sims.jpg",
    role: "Vice President",
    email: "sims.587@osu.edu",
    bio: `Ancient Greek enthusiast.`,
    quote: `"The sentence 'If wishes were horses, then beggars would ride' is vacuously true because wishes are not horses." - Neil Falkner`,
    linkedin: "https://www.linkedin.com/in/joshua-sims-2b9162265/",
    github: "https://github.com/jm8/",
  },
  {
    active: "info",
    actionKey: "bt",
    name: "Blake Theis",
    image: "img/board/blake.jpg",
    role: "Treasurer",
    email: "theis.85@buckeyemail.osu.edu",
    bio: `I want to be void.`,
    quote: `"If you optimize everything, you will always be unhappy." - Donald Knuth"`,
    linkedin: "https://www.linkedin.com/in/blake-theis-45b17b260/",
    github: "https://github.com/blaketheis",
  },
];
